@mixin di-input-props {
    flex-shrink: 0;

    background-color: $input-bg-color;
    border: 1px solid $input-border-color;
    border-radius: 2px;
    overflow: hidden;

    display: inline-flex;
    flex-wrap: wrap;

    &:focus {
        outline: 0;
    }

    &::placeholder {
        font-size: 12px;
    }
}

.di-input {
    @include di-input-props;
    padding: 7px;
    user-select: auto;
}

.di-input-form-simple {
    display: flex;

    .di-input {
        flex: 1;
        margin-right: 15px;
    }
}

/* This form is intended to be used for input files like:
   - Label [optional]
   - Input field [ of class .di-input]
   - Error message [optional]
*/
.di-input-form {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;

    padding-left: $di-padding-inner;
    padding-right: $di-padding-inner;
    margin-top: 10px;

    .input-section {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .di-input {
            flex: 1;
        }
    }
}

.di-input-text {
    font-size: 12px;
    height: 17px;
}

.di-input-label {
    color: $text-secondary-color;
}

.di-note-sm {
    font-size: 11px;
    color: $vw-grey;
}

.di-note-black {
    font-size: 11px;
    font-style: normal;
    font-weight: normal;
    color: black;
}

.di-input-error {
    display: block;
    height: 17px;
    color: $vw-red;
}

.di-input-warning {
    color: $vw-yellow;
}


/* TAGS INPUT - For entering emails */
$tag-text-color: #aaaaab;
$tag-height: 24px;
$tags-font-size: 12px;
$tags-input-padding: 4px;

.selectize-control {
    flex: 1;
    display: flex;
    position: relative;
}

.selectize-input {
    @include di-input-props;
    flex: 1;

    max-height: 120px;
    padding: 4px;
    padding-top: 0;

    font-size: $tags-font-size;
    overflow-y: auto;

    input {
        min-width: 15px;
        max-width: 100%;
        margin-top: 4px;
        height: $tag-height;
        background-color: transparent;
        border: 0;

        flex: 1;

        &:focus {
            outline: none;
        }
    }

    .tag {
        @include text-collapse();
        max-width: 100%;
        position: relative;
        margin-right: 5px;
        margin-top: 4px;
        padding-left: 5px;
        padding-right: 20px;
        height: $tag-height;
        line-height: $tag-height - 2px;
        color: $tag-text-color;
        background-color: $white;
        border: 1px solid $border-color;
        border-radius: 2px;
        display: inline-block;
    }

    .invalid-email {
        color: #d81f30;
        background-color: #fce2ed;
        border: 1px solid #d81f30;
    }

    .warning-email {
        color: $vw-yellow;
        background-color: #fceba5;
        border-color: #cabd69;
    }

    .remove {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 17px;
        text-align: center;
        font-weight: bold;
        color: inherit;
        text-decoration: none;
        vertical-align: middle;
        display: inline-block;
        padding: 1px 4px 0 0;
        box-sizing: border-box;
        cursor: pointer;
    }
}

.selectize-dropdown {
    max-height: 150px;
    position: absolute;

    background-color: $white;
    border: 1px solid $border-color;
    border-top: 0;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    box-shadow: 2px 2px 2px #a7a7a7;

    z-index: index($dialog-z, 'selectize-dropdown');

    .selectize-dropdown-content {
        max-height: 120px;
        overflow: auto;
    }

    .option {
        padding: 5px 10px;
        display: flex;
        align-items: center;

        .user-info {
            padding: 0 10px;
        }

        .label,
        .caption {
            display: block;

            .space {
                width: 10px;
                display: inline-block;
            }
        }

        .caption {
            color: $vw-grey;
        }
    }

    .option.active {
        color: $vw-annual-color;
        background-color: rgba($vw-annual-color, 0.05);
        cursor: pointer;
    }

    .highlight {
        font-weight: bold;
    }

    .create {
        padding: 5px 10px;
        color: $vw-grey;
    }
}


/* For Rename Dialog */
#dialog-rename {
    .di-input-form {
        position: relative;
    }

    .di-input-extension {
        position: absolute;
        line-height: 66px;
        right: 28px;
    }
}

.browse-with-path-input {
    display: flex;
    
    .btn-browse {
        line-height: 100%;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-left: -2px;
        background-color: white;

        cursor: pointer;
    }
    
    input {
        flex: 1;   
    }
}
