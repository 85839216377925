@use 'sass:math';

$versions-height: 176px;
$version-height: 130px;
$version-width: 130px;
$version-image-size: 47px;
$timeline-bar-width: math.div($version-width, 2);


// background
.fv-overlay {
    left: 0;
    right: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: $white;
    display: flex;
    animation: animatezoom 0.3s;

    z-index: index($global-z, 'fileview');

    .vw-collapse {
        font-size: 12px;
    }
}

.file-view {
    flex: 1;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.fv-toolbar {
    z-index: index($fileview-z, 'toolbar');
}

.fv-ver-wrap {
    flex: none;
    display: flex;
    align-self: center;
    max-width: 100%;
}

#fv-clps-div {
    overflow: hidden;
    transition: height 0.5s;
    max-width: 100%;
}

.fileview-component-loader,
.viewer-wrapper {
    flex: 1 1 0;
    display: flex;
    -webkit-overflow-scrolling: touch;
    overflow: auto;

    position: relative;

    background-color: $white;

    transition: background-color 0.5s;

    .regular-image {
        max-width: 100%;
        max-height: 100%;
        align-self: center;
        flex-shrink: 0;
        margin: 0 auto;
        object-fit: contain;
        image-orientation: from-image;
    }

    .dragging {
        cursor: grabbing !important;
    }

    .draggable {
        cursor: grab;
    }
}

.viewer-wrapper {
    flex-direction: column;
    overflow: hidden;
}

.fileview-component-loader.centered {
    justify-content: center;
}

.image-viewer,
.panorama-viewer {
    overflow: hidden;
}

.no-preview {
    background-color: $white;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .message-box {
        @include respond-to(phone) {
            flex-direction: column;
            justify-content: center;
        }
        max-width: 600px;
        margin: 0;
        padding: 0;
    }

    .message {
        @include respond-to(phone) {
            max-width: 300px;
        }
    }

    .prim-column {
        @include respond-to(phone) {
            display: flex;
            flex: 1;
            justify-content: center;
        }
    }

    .dw-link {
        margin-left: 10px;
        cursor: pointer;
    }

    .download-link {
        height: 50px;

        display: flex;
        align-items: center;

        color: $vw-annual-color;
    }

    .icon-download {
        color: $vw-annual-color;
        opacity: 1;
        font-size: var(--vcs-icon-size-md);
    }

    .vwx-download {
        margin-left: -5px;
    }
}

iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    border: 0;
}

.fv-arrow {
    color: $vw-grey;
}

.no-preview-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    a {
        cursor: pointer;
    }
}

.file-link-preview {
    position: relative;
    flex: 1;
    display: flex;
    overflow-x: hidden;
}

#panorama-image {
    width: 100%;
    overflow: hidden;
    display: flex;

    .plain-image {
        max-width: 100%;
        min-width: 0;
        max-height: 100%;
        margin: 0 auto;
    }
}

.canvas-fullscreen {
    z-index: 100;
}

.webvr-polyfill-viewer-selector {
    z-index: 110;
    position: absolute;
}

.a-orientation-modal {
    button {
        margin-top: 70px !important;
    }
}

.full-screen {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
    z-index: 999999;
}

.mouse-on-link {
    cursor: pointer;
}

.mouse-on-move-target {
    cursor: move;
}

.image-svg {
    height: 300px;
}

.transparent {
    opacity: 0;
}

.branding-logo {
    transition: opacity 0.3s ease-in;
}

.vgx-loader-loading {
    margin-top: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    #loading-title {
        margin-top: 20px;
        font-size: 14px;
        color: var(--text-primary-color);
    }
}

// Show in Nomad toast styles
.nomad-style-toast {
    margin-top: 95px;
    min-width: 98vw;
    background-color: var(--black) !important;
    color: var(--white) !important;

    .icon-none {
        display: none;
    }

    div:nth-child(2) {
        display: flex;
        justify-content: center;
    }

    a {
        text-transform: uppercase;
        text-decoration: underline;
    }
}
