@import '../utils/mixins';
@import '../utils/variables';


#sidebar-wrapper {
    @include respond_to_at_least(small_screen) {
        justify-content: space-between;

        global-progress {
            margin-bottom: 10px;
        }
    }

    @include respond_to(phone) {
        top: $navbar-height-xs !important;
    }

    z-index: index($main-z, 'sidebar');
    position: absolute;
    top: $navbar-height;
    bottom: 0;
    left: 0;
    width: 0;

    font-size: 14px;
    border-right: 1px solid $border-color;
    background-color: white;
    display: none;
    overflow-y: auto;
    overflow-x: hidden;

    .list-providers {
        position: absolute;
        left: 20px;
        width: 170px;
        list-style: none;
        box-shadow: $thumb-shadow;
        background-color: white;
    }
}

.sidebar-link {
    @include link-no-decoration($text-primary-color);
    @include vw-hover-animation($text-primary-color);
    @include text-collapse();
    line-height: $sidebar-link-height;
    display: block;
    font-weight: 500;

    .icon {
        color: $text-primary-color;
        padding: $sidebar-icon-padding;
        margin-left: 12px;
        font-size: $sidebar-icon-size;
        vertical-align: middle;
    }
}

/* stylelint-disable */
@mixin provider-svg-color($color) {
    #google_drive {
        .st0{fill:$color;}
        .st1{fill:$color;}
        .st2{fill:$color;}
    }

    #dropbox {
        .st0{fill:$color;}
    }
}

@mixin ext-provider-svg-coloring {
    svg {
        width: var(--vcs-icon-size-md);
        height: var(--vcs-icon-size-md);
        display: block;
        
        path:not([fill='none']) {
            fill: $text-primary-color;
        }

        path, polygon {
            transition: fill 0.4s ease;
        }
    }

    &:hover {
        color: $text-primary-color;

        #google_drive {
            .st0{fill:#FFCF4A;}
            .st1{fill:#1CA261;}
            .st2{fill:#4687F4;}
        }

        #dropbox {
            .st0{fill:#0061FF;}
        }
    }
}

.ext-provider {
    @include ext-provider-svg-coloring;

    .icon {
        display: inline-block;
    }
}

.ext-provider.item-selected {
    svg path:not([fill='none']){
        fill: white;
    }

    &:hover {
        @include provider-svg-color(white);
    }
}
/* stylelint-enable */

.integration-link {
    @include ext-provider-svg-coloring;
    @include ctx-menu-item--detail;

    svg {
        position: absolute;
        left: 0;
    }
}

@include sidepanel-toggling($sidebar-width);
