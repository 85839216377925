.hv-ls-item {
    border-bottom: 1px solid $border-color;

    .name {
        @include make-sm-column(6);
    }

    .last-mod {
        /* stylelint-disable */
        @extend .hidden-xs;
        /* stylelint-enable */
        @include make-sm-column(3);
        @include make-lg-column(2);
    }

    .size {
        /* stylelint-disable */
        @extend .hidden-xs;
        /* stylelint-enable */
        @include make-sm-column(1);
        @include make-lg-column(2);
    }

    .flags {
        /* stylelint-disable */
        @extend .hidden-xs;
        /* stylelint-enable */
        @include make-sm-column(2);
    }
}

.hv-asset-ls-item {
    @include asset-list-item();

    .name {
        /* stylelint-disable */
        @extend .hidden-xs;
        /* stylelint-enable */
    }

    /* List for xs screens only */
    .xs-list {
        @include make-xs-column(12);
        /* stylelint-disable */
        @extend .visible-xs-block;
        /* stylelint-enable */
        padding-left: $list-image-padding;
        margin-top: 6px;

        .attr {
            line-height: normal;
            color: $text-secondary-color;
        }

        .attr-primary {
            @include make-row();
            @include make-xs-column(12);
            color: $text-primary-color;
        }

        .xs-attributes {
            @include make-row();

            .attr-left {
                @include make-xs-column(8);
                font-size: 11px;
            }

            .attr-right {
                @include make-xs-column(4);
                font-size: 11px;
            }
        }
    }
}
