.di-ls-item {
    border-bottom: 1px solid $border-color;

    .name {
        @include make-xs-column(12);
    }
}

.di-asset-ls-item {
    @include asset-list-item();
}
