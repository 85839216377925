.presentation-ls-item {
    border-bottom: 1px solid $border-color;

    .name {
        @include make-xs-column(10);
        @include make-sm-column(4);
        @include make-lg-column(6);
        @include list-name-path-attr();
    }

    .last-modified,
    .date-created {
        /* stylelint-disable */
        @extend .hidden-xs;
        /* stylelint-enable */
        @include make-sm-column(3);
        @include make-lg-column(2);
    }

    .flags {
        @include make-xs-column(2);
    }
}

.presentations-trash-ls-item {
    border-bottom: 1px solid $border-color;

    .name {
        @include respond_to(phone) {
            @include make-xs-column(12);
        }
        @include make-sm-column(9);
        @include make-lg-column(10);
        @include list-name-path-attr();
    }

    .last-modified {
        /* stylelint-disable */
        @extend .hidden-xs;
        /* stylelint-enable */
        @include make-sm-column(3);
        @include make-lg-column(2);
    }
}

.presentation-asset-ls-item {
    @include asset-list-item();
}
