/* Thumb view global variables */
$thumb-width: 210px;
$thumbs-margin: 15px;

/*
   For files you can select the thumb height and the size of the image.
   The padding will be adjusted accordingly.
   Make sure that the $file-thumb-height is more than the $file-thumb-size
*/
$file-thumb-height: 340px;
$file-thumb-size: $thumb-width;

/* Thumb folders variables */
$folder-height: 50px;
$folder-thumb-size: 26px;
$folder-padding: 10px;
$folder-line-height: $folder-thumb-size;  /* The height of a list asset item minus double the padding */
$folder-image-padding: $folder-thumb-size + $folder-padding; /* Thumbs are absolutely positioned, so we add padding to fit them */
$thumb-flags-size: 24px;

/* This class is used of the smaller screens thumbs view to separate
   the elements from each other without the use of margins.
   This class allows us to use the columns paddings as margins
   without changing the above logic. Also it's making the images responsive.
*/
@mixin auto-size {
    padding: 0 5px;
    margin-bottom: 10px;

    .thumb {
        width: 100%;
        margin-left: 0;
    }

    .file-thumb {
        height: auto;
    }

    .file-thumb .asset-img {
        width: 100%;
        height: auto;
        padding-bottom: 100%;
    }

    .name {
        width: 100%;
    }
}

.thumbs-wrapper {
    @include respond-to(phone) {
        @include auto-size();
        width: 50%;
    }

    @include respond-to(tablet) {
        @include auto-size();
        width: 25%;
    }

    @include respond-to(small_screen) {
        @include auto-size();
        width: 25%;
    }

    float: left;

    .item-selected-light {
        border-bottom: 0 !important;
        border-top: 0 !important;
    }
}

.slide--thumbs-wrapper.di-thumbs-wrapper {
    @include respond-to(phone) {
        width: 50%;
    }

    @include auto-size();
    width: 33%;
}


.thumb {
    @include respond-to-at-least(small_screen) {
        @include make-xs-column(12);
        margin: 0;
        padding: 0;

        .name {
            width: 100%;
        }
    }

    position: relative;
    width: $thumb-width;
    float: left;
    margin-left: $thumbs-margin;
    margin-bottom: $thumbs-margin;
    font-size: 12px;

    background-color: $file-bg-color;
    box-shadow: $thumb-shadow;
}

.thumb.item-selected-light {
    @include thumb-selected-shadow(2px, 0);
}

/* Folders thumb view */
.folder-thumb {
    @include shared-badge(-75%, -115%, 32px);
    @include permission-badge(20%, -180%);

    height: 73px;
    padding: 9px 13px 13px;
    line-height: $folder-line-height;

    .asset-img {
        margin: 5px;
        position: absolute;
        font-size: var(--vcs-icon-size-md);
    }

    .name {
        padding-left: $folder-image-padding;
        margin-top: 4px;
    }

    .canvas-flags {
        margin-right: -12px !important;
    }
}

/* Thumbs File View */
.file-thumb {
    @include shared-badge(-75%, -115%, 32px);
    @include permission-badge(20%, -180%, 32px);

    .asset-img {
        @include fit-background--cover;
        height: $file-thumb-size + 1px;
        width: $file-thumb-size;
    }

    .thumb-default-icon {
        font-size: var(--vcs-icon-size-md);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(2);
        zoom: 0.5;
        /* stylelint-disable */
        -ms-zoom: 0.5;
        -moz-transform: translate(-50%, -50%) scale(1);
        /* stylelint-enable */
    }

    .folder {
        svg {
            height: 64px;
            width: 64px;
        }
    }

    .name {
        max-width: 100%;
    }

    .size {
        width: 100%;
        line-height: 32px;
        color: $text-secondary-color;
        font-size: 11px;
    }
}

.thumbs-wrapper .small-thumb-folders .file-thumbs-loader {
    @include shared-badge(-100%, 72%, 16px);
    @include permission-badge(-22%, 0%, 16px);

    position: relative;
}

.thumb-info {
    padding: 0 5px;

    .info-secondary {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        line-height: 32px;
        height: 32px;
    }

    .canvas-attr {
        @include text-collapse();
        flex: 1;
        color: $text-secondary-color;
        font-size: 11px;
    }

    .storage-provider {
        display: flex;
        align-items: center;
        color: $text-secondary-color;
    }

    .storage-icon {
        flex-shrink: 0;
    }

    .canvas-flags {
        flex-shrink: 0;

        .flag {
            margin-left: 5px;
            font-size: 16px;
            float: right;
        }
    }

    .shared-with-list-avatar {
        flex-shrink: 0;
    }
    
    .link-visits-count.lc-hidden {
        display: none;
    }
}

.separator {
    @include respond-to(large_screen) {
        margin-left: 0;
    }

    line-height: 40px;
    height: 40px;
    color: #c9c8c7;
    font-weight: bold;
    margin-left: -10px;
}
