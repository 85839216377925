/* The switch - the box around the slider */
.switch {
    width: 50px;
    height: 25px;

    position: relative;
    display: inline-block;

    /* Hide default HTML checkbox */
    input {
        display: none;
    }
}

.switch.not-allowed {
    cursor: not-allowed;
    pointer-events: none;

    .switch-slider {
        cursor: not-allowed;
    }
}

/* The slider */
.switch-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;

    pointer-events: none;
}

.switch-slider::before {
    content: '';
    position: absolute;
    height: 17px;
    width: 17px;
    left: 4px;
    bottom: 4px;
    background-color: $white;
    transition: 0.4s;
}

input:checked + .switch-slider {
    background-color: $vw-annual-color;
}

input:focus + .switch-slider {
    box-shadow: 0 0 1px $vw-annual-color;
}

input:checked + .switch-slider::before {
    transform: translateX(26px);
}

/* Rounded sliders */
.switch-slider.round {
    border-radius: 34px;
}

.switch-slider.round::before {
    border-radius: 50%;
}
