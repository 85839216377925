#presentation-edit {
    @include respond-to(phone) {
        top: $toolbar-height-xs;
    }

    top: $toolbar-height;
    position: absolute;
    flex: 1;
}

.pr-tool {
    background-color: $white;
    border: 1px solid $border-color;
    border-radius: 5px;
    box-shadow: 2px 2px 2px #a7a7a7;
}

.tool-palette {
    margin: 10px;
    display: flex;
    flex-direction: column;
}
