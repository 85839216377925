.mentions-field {
    flex: 1;
    max-height: 200px;
    overflow: auto;

    [contenteditable=true] {
        padding: 0 5px;
        color: rgba($black, 0.6);
        outline: 0;
        /* For Firefox */
        white-space: pre-wrap;
        word-break: normal;

        /* For Chrome and IE */
        word-wrap: break-word;
        
        color: var(--text-primary-color);
        background-color: var(--input-bg-color);
        border: 1px solid var(--input-border-color);
        border-radius: 2px;
    }

    [contenteditable=true]::before {
        content: attr(placeholder);
        position: relative;
        opacity: 0.5;
    }

    [contenteditable=true]:not(:empty)::before {
        display: none;
    }

    .suggestions-dropdown {
        width: 100%;
        max-height: 150px;
        background-color: var(--bg-color);
        border: 1px solid var(--border-color);
        overflow: auto;
    }
}

.mention {
    color: $vw-annual-color;
    cursor: default;
}

.mf-input::before {
    padding-top: 3px;
}
