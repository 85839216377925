/* Styles for the PDF viewer */

@import '~pdfjs-dist/web/pdf_viewer.css';

#viewerContainer,
#viewerContainerFileView {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: auto;
    user-select: auto;

    /* stylelint-disable */
    /* Overriting some default styles */
    .pdfViewer {
        margin: 15px 0;

        .page {
            margin: 1px auto 10px auto;
            border: 0;
            border-image: none 100% 1 0 stretch;
        }
        .canvasWrapper {
            border: 1px solid rgba($black, 0.1);
        }
    }
    /* stylelint-enable */

    .portal {
        position: absolute;
        text-align: center;
    }

    .portal:hover {
        .portal-buble {
            opacity: 1;
            visibility: visible;
        }
    }

    .portal-buble {
        @include text-collapse();
        padding: 5px;
        color: $white;
        background-color: rgba($black, 0.7);
        border-radius: 5px;

        opacity: 0;
        visibility: hidden;
        transition: visibility 0s, opacity 0.3s ease;
    }
}

.grab-to-pan-grab {
    cursor: grab;
    user-select: none;
}

.draggable-source--is-dragging {
    opacity: 0;
}

.draggable-container--is-dragging {
    cursor: move;
}

.draggable-container--over {
    cursor: move;
    user-select: none;
}
