/* Global progress */
$gl-pr-value: #4c4b4a;
$gl-pr-height: 20px;
$gl-pr-font-size: 10px;
$gl-pr-width-step: 25px;
$span-bottom: 2px;

$global-progress-z: 'header-progress', 'job-count';

global-progress {
    margin-top: 2px;
    cursor: pointer;

    .jobs-count {
        width: auto;
        min-width: 20px;
        height: 20px;
        margin-left: 25px;
        margin-top: -12px;
        position: absolute;
        font-size: 12px;
        line-height: 20px;
        font-weight: bold;
        border-radius: 50%;
        text-align: center;
        color: white;
        background-color: $vw-annual-color;
        box-shadow: -0.707px 0.707px 2px 0 rgba(0, 0, 0, 0.72);
        font-family: 'Roboto', 'Noto Sans', sans-serif;

        z-index: index($global-progress-z, 'job-count');
    }

    .jobs-count.ie {
        margin-left: -10px;
        margin-top: -24px;
    }
}

.svg {
    margin-top: -2px;
    margin-left: -2px;
    position: absolute;
    z-index: index($global-progress-z, 'header-progress');

    .bar {
        stroke: $vw-annual-color;
        stroke-width: 3px;
        transform: rotate(-90deg);
        transform-origin: 50% 50%;
        transition: stroke-dashoffset 0.5s linear;
    }
}

.global-progress {
    display: block;
    height: 34px;
    width: 34px;
    margin: 2em auto;
    border-radius: 50%;
    position: relative;

    .icon-indicator-status-32 {
        position: absolute;
        top: 0;
    }
}

/* PROGRESS DIALOG */

$status-pending: #000;
$delete-job-size: 32px;
$status-icons: 16px;
$job-icon-size: 24px;

#dialog-progress {
    @include respond_to(phone) {
        .btn-clear-finished {
            padding: 0;
        }
    }

    .vw-tabs {
        margin-top: -1px;
    }

    .dialog-inner {
        display: flex;
        flex-direction: column;
    }

    .status {
        gap: 5px;
        display: flex;
        align-items: center;

        .icon {
            font-size: $status-icons;
        }
    }

    .icons-wrap {
        justify-content: center;
    }

    .icon-column {
        .icon {
            margin-right: 3px;
            padding: 4px;
        }
    }
}

.in-web-view {
    .progress-ls-item .status {
        padding-left: 5px;
        padding-right: 5px;
    }
}

#dialog-progress.in-web-view {
    height: 100%;

    .di-btn-prim {
        display: none;
    }

    .dialog-header {
        height: 4px;
    }
}

.progress-col-bar {
    @include make-row();
    @include reset-horizontal-margin();
    line-height: 36px;
}

.progress-ls-item,
.progress-xs-item {
    .icon.icon-info-16 {
        position: relative;
        z-index: 1;
    }

    .icon.icon-info-16.active {
        position: relative;
        color: white !important;
    }

    .icon.icon-info-16.active::after {
        top: 0;
        left: 0;

        height: 24px;
        width: 24px;

        content: '';
        z-index: -1;
        border-radius: 50%;
        position: absolute;
        background-color: $vw-annual-color;

        &:hover {
            background-color: $vw-annual-color;
            color: white;
        }
    }
}

.progress-xs-item .icon.icon-info-16.active::after {
    top: 0;
    left: 0;
}

.progress-ls-item {
    border-bottom: 1px solid $border-color;

    .job-icon {
        height: 40px;
        width: 40px;
        position: absolute;
        left: 10px;
        padding: 1px;
        font-size: $job-icon-size;
        background-color: $text-primary-color;
        color: $white;
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .name {
        @include respond_to(phone) {
            @include make-xs-column(8);
        }        @include make-sm-column(5.5);
        @include list-name-path-attr();
        color: $text-primary-color;

        .name-text {
            display: flex;
            flex-direction: column;
        }

        .name-text .name-sec-row {
            @include text-collapse();
            margin-top: -3px;
            font-size: 11px;
            color: $text-secondary-color;
        }
    }

    .size {
        @include make-xs-column(1);
        padding-right: 0;
        white-space: pre;
    }

    .status {
        @include make-xs-column(3.5);
    }

    .status-text,
    .percent {
        vertical-align: super;
    }

    .status-text {
        @include text-collapse;
    }

    .icon-column {
        @include make-xs-column(2);
        height: 100%;
        display: flex;
        justify-content: flex-end;

        .icons-wrap {
            height: 40px;
            position: relative;
            display: inline-grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 5px;
            justify-content: center;
            align-items: center;
        }

        .icon {
            cursor: pointer;
        }
    }

    .date-submitted {
        @include respond_to(phone) {
            @include make-xs-column(3);
        }
        @include make-sm-column(2.5);
        padding: 0 !important;
    }
}


#dialog-confirm-job-cancel {
    .job-icon {
        height: 40px;
        width: 40px;
        font-size: $job-icon-size;
        background-color: $text-primary-color;
        color: $white;
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .di-thumb {
        width: 40px;
        height: 40px;
    }

    .di-list-item .item-name {
        margin-left: 10px;
    }
}

.previous-jobs-tab .progress-ls-item,
.previous-jobs-tab .progress-ls-file {
    .name {
        @include respond_to(phone) {
            @include make-xs-column(7);
        }
        @include make-sm-column(4);
        @include list-name-path-attr();
    }
}


.mobile-jobs-container {
    overflow-x: hidden;
    overflow-y: auto;
}

.progress-ls-file {
    @include asset-list-item();
    @include reset-horizontal-margin();

    .percent {
        padding-right: 5px;
    }
}

.container-wrapper {
    @include reset-margins();
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
}

.jobs-container {
    @include reset-margins();
    overflow-x: hidden;
    overflow-y: auto;
}

.previous-jobs-tab .progress-ls-file .name {
    padding-left: 70px;
}

.left {
    float: left !important;
}

.right {
    float: right !important;
}

progress-bar {
    position: absolute;
    width: 100%;
    bottom: -1px; /* The progress overwrites the border-bottom of the file */
    left: 0;
    right: 0;
}

.pr-value {
    height: 1px;
    background-color: $vw-annual-color;
    transition: width 0.5s;
}

.progress-xs-item {
    min-height: 50px;
    margin: 0 -5px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $border-color;
    position: relative;

    .job-icon,
    .icon-column,
    .status {
        flex: 1;
        display: flex;
        justify-content: center;
    }

    .icon-column {
        flex: 2;
    }

    .icon.icon-circle {
        width: 40px;
        height: 40px;
        padding: 1px;

        display: flex;
        align-items: center;
        justify-content: center;

        color: $white;
        background-color: $text-primary-color;
        border-radius: 50%;
    }

    .icon-column {
        padding: 0 10px !important;
        justify-content: flex-end;

        .icon:hover {
            color: $vw-annual-color;
        }
    }

    .size {
        /* stylelint-disable */
        @extend .hidden-xs;
        /* stylelint-enable */
    }

    .job-icon {
        font-size: var(--vcs-icon-size-md);
    }

    .icon,
    .status {
        color: $text-primary-color;
    }

    .percent {
        margin-left: 5px;
    }

    .file-info-wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .path {
            color: $text-secondary-color;
        }
    }

    .job-info-wrapper {
        margin: 10px 0;
        flex: 5;
        display: flex;
        flex-direction: column;
        justify-items: flex-start;
        overflow: hidden;

        .status {
            justify-content: flex-start;
            align-items: center;
        }
    }
}

.icon-column {
    gap: 5px;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;

    .icon {
        font-size: 16px;
        transition: color 0.5s;
        cursor: pointer;
    }

    .icon:hover {
        color: $vw-annual-color;
        transition: color 0.5s;
    }
}

.job-info {
    position: absolute;
    padding: 0;
    margin: 0;
    z-index: 1030;

    ul {
        @include respond_to(phone) {
            width: 90vw;
        }
        width: 350px;
        padding: 10px;
        margin: 0;
        line-height: 1.7;
        background-color: $white;
        border: 1px solid $border-color;
        border-radius: 10px;
        box-shadow: 4px 4px 8px rgba($black, 0.3);

        li {
            position: relative;
            margin: 0;
            z-index: 1031;
            list-style-type: none;
            white-space: nowrap;
            color: $text-primary-color;
        }
    }

    .title {
        margin-bottom: 15px;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: bold;
    }

    .stat-row {
        display: flex;
        justify-content: space-between;
    }

    .stat-title {
        font-size: 12px;
    }

    .stat-content {
        width: 200px;
        padding-left: 5px;
        white-space: normal;
        word-break: normal;

        /* For Chrome and IE */
        word-wrap: break-word;
    }

    .icon {
        font-size: 24px;
    }
}

.job-info__info__section {
    width: 100%;
    background-color: $page-bg-color;
}

.job-info__expandable {
    font-size: 12px;
}

.job-info__info__table {
    @include respond_to(phone) {
        padding: 8px;
    }

    padding: 8px 20px 8px 70px;
    width: 100%;
    font-size: 12px;
    line-height: 15px;
}

.job-info__row {
    display: flex;
    align-items: flex-start;

    .job-info__row__title {
        @include text-collapse;
        flex: 3;
    }

    .job-info__row__content {
        @include text-collapse;
        flex: 8 1 0;
        display: flex;
        align-items: center;

        span {
            @include text-collapse;
            min-width: 0;
        }
    }

    .icon--provider {
        font-size: 16px;
        margin-right: 4px;
        color: $vw-grey;
        flex-shrink: 0;
    }
}
