/* Styles for the controller/toolbar in file view
    (aka container of arrows, zoom, etc.)
*/

.controller {
    @include respond-to-at-least(small_screen) {
        height: 40px;
        padding: 0;

        .volume-popup {
            display: none !important;
        }
    }

    @include respond-to(phone) {
        .icon-hand,
        .ctrl-tool.zoom {
            display: none !important;
        }
    }


    height: 50px;
    left: 50%;
    position: absolute !important;
    bottom: -50px;

    color: $white;
    z-index: index($fileview-z, 'controller');
    transition: bottom 0.3s;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    transform: translateX(-50%);

    button {
        color: inherit;

        &:hover {
            color: white; // Can use inherit to enable hover animation
            fill: white;
        }
    }

    .controller-inner {
        @include respond-to-at-least(tablet) {
            font-size: 11px;
        }

        height: 100%;
        width: auto;
        margin: auto;

        display: inline-flex;
        justify-content: center;
    }

    .control-section {
        @include respond-to(phone) {
            padding: 0;
        }

        min-width: 75px;
        padding: 0 5px;
        background-color: rgba(var(--grey13-rgb), 0.75);
        display: flex;
        align-items: center;
    }

    .center {
        justify-content: center;
    }

    .right {
        @include respond-to-orientation(portrait) {
            .controller-input,
            .num-pages,
            .page-control {
                margin: 0;
                padding: 0;
            }
        }
        margin-left: 1px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;

        justify-content: flex-start;
        display: inline-flex;

    }

    .left {
        margin-right: 1px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;

        justify-content: flex-end;
        display: inline-flex;
    }

    .control-section.left,
    .control-section.right {
        @include respond-to(phone) {
            min-width: 34px;
            max-width: 34px;
        }
        min-width: 48px;
        max-width: 48px;
    }

    .icon {
        font-size: var(--vcs-icon-size-md);
        cursor: pointer;
    }

    .ctrl-tool {
        padding: 3px;
        display: flex;
        align-items: center;
    }

    .ctrl-tool.active {
        border-radius: 50%;
        background: rgba($black, 0.3);
    }

    .file-navigation {
        width: max-content;
    }

    .num-pages {
        margin: 0 5px;
    }

    .controller-input {
        width: min-content;
        min-width: 10px;
        margin: 0 5px;
        padding: 0 3px;

        color: $white;
        background-color: rgba($black, 0.1);
        border-radius: 5px;
        border: 0;
        text-align: center;

        &:active,
        &:focus {
            outline: none;
        }
    }

    &.ft-image,
    &.ft-heic {
        .control-section.left,
        .control-section.right {
            @include respond-to(phone) {
                min-width: 34px;
                max-width: 34px;
            }
            min-width: 130px;
            max-width: 130px;
        }
    }

    &.ft-panorama {
        .control-section.left,
        .control-section.right {
            @include respond-to(phone) {
                min-width: 34px;
                max-width: 34px;
            }
            min-width: 48px;
            max-width: 48px;
        }
    }

    &.ft-pdf {
        .control-section.left,
        .control-section.right {
            @include respond-to(phone) {
                min-width: 148px;
                max-width: 148px;
            }
            min-width: 205px;
            max-width: 205px;
        }
    }

    &.ft-video,
    &.ft-video_360 {
        .control-section.left,
        .control-section.right {
            @include respond-to-at-least(small_screen) {
                min-width: 140px;
                max-width: 140px;
            }
            min-width: 237px;
            max-width: 237px;
        }
    }

    &.ft-vgx {
        .control-section.left,
        .control-section.right {
            @include respond-to(phone) {
                min-width: 136px;
                max-width: 136px;
            }
            min-width: 162px;
            max-width: 162px;
        }
    }
}

.controller.expanded {
    bottom: 20px;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.a-enter-vr {
    right: 0 !important;
}

#a-enter-vr-button {
    display: none;
}
