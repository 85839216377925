@import '../utils/variables';

.search-input__wrap {
    position: relative;

    .icon-x-16 {
        position: absolute;
        top: 10px;
        right: 10px;

        font-size: 16px;
        color: $text-primary-color;
    }
    
    .di-input {
        padding-right: 30px;
        width: 100%;
    }
}

#search-widget-wrap {
    width: 340px;
    float: left;
    margin-top: 14px;
    margin-right: 10px;

    .icon-x-16 {
        display: none;
    }
}

#mobile-search-widget {
    @include respond-to-at-most(tablet) {
        display: none !important;
    }
    
    display: none;

    #search-widget-wrap {
        position: absolute;
        top: $navbar-height-xs;
        width: 100%;
        height: $toolbar-height-xs - 1;

        padding: 9px;
        margin: 0;

        background-color: white;
        z-index: index($main-z, 'mobile-search');

        .icon-x-16 {
            display: block;
            position: absolute;
            top: 17px;
            right: 15px;
            font-size: 16px;
        }
    }
}

#mobile-search-widget.search-displayed {
    display: block;
}

#select-dialog__search { 
    @include respond-to(phone) {
        margin: 9px 12px;
    }
    
    flex: 1;
    margin: 14px 12px;
}
