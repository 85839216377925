/* Date picker */
$white: #fff;
$light-gray-1: #e0e0e0;
$light-gray-2: #7a7a7a;
$dark-border: #ccc;
$highlight-color: #ebebeb;

.expire-on {
    min-height: 36px;
    display: flex;
    align-items: center;
}

/* stylelint-disable */
.datepicker-wrapper {
    width: 150px;
    margin-left: 35px;
    border: 1px solid $border-color;
    border-radius: 2px;

    input,
    span {
        border: 0;
    }

    .icon-arrow-down {
        font-size: 5px;
        cursor: pointer;
    }
}
/* stylelint-enable */

.datepicker-input {
    border-right: 0;
    background-color: $white;
    box-shadow: none;
    pointer-events: none;

    &:focus {
        border-color: $dark-border;
        box-shadow: none;
    }
}

.input-group-addon {
    background-color: $white;
    width: 37px;
}

.datepicker {
    padding: 4px;
    border-radius: 4px;


    .date-picker-dropdown {
        top: 0;
        left: 0;
    }

    table {
        margin: 0;
        user-select: none;
    }

    td,
    th {
        text-align: center;
        width: 20px;
        height: 20px;
        border-radius: 4px;
        border: 0;
    }

    // Inline display inside a table presents some problems with
    // border and background colors.
    .table-striped & table tr {
        /* stylelint-disable */
        td,
        th {
            background-color: transparent;
        }
        /* stylelint-enable */
    }

    .day:hover,
    .day:focus {
        background: $light-gray-1;
        cursor: pointer;
    }

    .old,
    .new {
        color: $light-gray-2;
    }

    .disabled,
    .disabled:hover {
        background: none;
        color: $light-gray-1;
        cursor: default;
    }

    .today,
    .today:hover,
    .today.disabled,
    .today.disabled:hover {
        background-color: $highlight-color;
        color: #000;
    }

    .today:hover {
        color: #000;
    }

    .today .active:hover {
        color: $white;
    }

    .active,
    .active:hover,
    .active.disabled,
    .active.disabled:hover {
        background-color: $light-gray-1;
        color: #fff;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    }

    span {
        display: block;
        width: 23%;
        height: 54px;
        line-height: 54px;
        float: left;
        margin: 1%;
        cursor: pointer;
        border-radius: 4px;

        &:hover,
        &.focused {
            /* stylelint-disable */
            background-color: $light-gray-1;
            /* stylelint-enable */
        }

        .disabled,
        .disabled:hover {
            background: none;
            color: $light-gray-1;
            cursor: default;
        }

        .active,
        .active:hover,
        .active.disabled,
        .active.disabled:hover {
            background-color: $light-gray-1;
            color: #fff;
            text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
        }

        .old,
        .new {
            color: $light-gray-2;
        }
    }


    .datepicker-switch {
        width: 145px;
    }

    .datepicker-switch,
    .prev,
    .next,
    tfoot tr th {
        cursor: pointer;
        /* stylelint-disable */
        &:hover {
            background: $light-gray-1;
        }
        /* stylelint-enable */
    }

    .prev,
    .next {
        .disabled {
            visibility: hidden;
        }
    }

    // Basic styling for calendar-week cells
    .cw {
        font-size: 10px;
        width: 12px;
        padding: 0 2px 0 5px;
        vertical-align: middle;
    }
}
