$history-panel-width: 320px;

.versions-history {
    @include respond-to(phone) {
        top: $toolbar-height-xs;
    }
    @include panel-animation($history-panel-width, left);
    @include panel-shadow--right;

    position: absolute !important;

    top: $toolbar-height;
    bottom: 0;

    background-color: white;
    z-index: index($fileview-z, 'panel');
    overflow: hidden;

    display: flex;
    flex-direction: column;

    .message-bubble {
        height: auto;
        padding: 10px 20px;

        justify-content: center;
        align-items: center;

        text-align: center;
        font-weight: normal;
    }
}

.versions-header {
    @media (max-width: $xs-max-width) {
        height: $toolbar-height-xs;
    }

    flex-shrink: 0;
    height: $toolbar-height;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 11px;

    color: $vw-grey;
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
    white-space: nowrap;

    border-bottom: 1px solid $border-color;

    .tool {
        font-size: 16px;
    }
}

.file-versions {
    flex: 1;
    overflow: auto;

    display: flex;
    flex-direction: column;
    padding-top: 1px;
}

.file-version {
    height: auto;
    min-height: 31px;
    padding: 0 15px;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    border-bottom: 1px solid $border-color;
    position: relative;
    cursor: pointer;

    .last-mod {
        @include primary-secondary-canvas(15px, 18px, 11px, 12px);
        margin-top: 7px;
        flex: 1;
        display: flex;

        .size {
            margin-left: 16px;
        }
    }

    .version-info {
        margin-bottom: 5px;
        min-width: 0;
        flex: 1;
    }

    .version-info.pending {
        margin: 0;
        gap: 5px;
        display: inline-flex;
        align-items: center;

        .desc {
            margin-top: 1px;
            color: $text-secondary-color;
        }
    }

    .edit-column {
        width: 27px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .label {
        margin-top: 5px;
        overflow: hidden;
        font-size: 11px;
        line-height: 12px;
        color: $text-secondary-color;
        /* For Firefox */
        white-space: pre-wrap;
        word-break: normal;

        /* For Chrome and IE */
        word-wrap: break-word;
    }

    .label-field {
        @include di-input-props;
        min-height: 25px;
        width: 100%;
        margin-top: 5px;
        padding: 0 5px;
        resize: none;
    }

    .label-field:focus {
        outline: 0;
    }

    .icon {
        color: $vw-grey;
        cursor: pointer;
        transition: color 0.4s ease;

        &:hover {
            color: $vw-annual-color;
            transition: color 0.4s ease;
        }
    }
}

.file-version.selected-borders {
    box-shadow: 0 -1px 0 $vw-annual-color;
    border-bottom: 1px solid $vw-annual-color !important;

    .lds--grey {
        border-color: $vw-annual-color;
        border-top-color: transparent;
    }

    .version-info.pending .desc {
        color: $vw-annual-color !important;
    }
}
