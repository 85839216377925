.hv-search-ls-item {
    border-bottom: 1px solid $border-color;

    .name {
        @include respond_to(phone) {
            @include make-xs-column(9);
        }
        @include make-xs-column(6);
        @include list-name-path-attr();
    }

    .last-mod,
    .size {
        /* stylelint-disable */
        @extend .hidden-xs;
        /* stylelint-enable */
        @include make-sm-column(2);
    }

    .flags {
        @include make-sm-column(2);
        @include make-xs-column(3);
    }
}

.hv-search-asset-ls-item {
    @include asset-list-item();
}
