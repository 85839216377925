@use 'sass:math';

$margin-overlap: -5px;

.sh-ls-item {
    border-bottom: 1px solid $border-color;

    .name {
        @include respond_to(phone) {
            @include make-xs-column(9);
        }
        @include make-xs-column(6);
        @include list-name-path-attr();
    }

    .shared-with,
    .last-mod {
        /* stylelint-disable */
        @extend .hidden-xs;
        /* stylelint-enable */
        @include make-xs-column(2);
    }

    .flags {
        @include make-sm-column(2);
        @include make-xs-column(3);
    }

    .shared-with-avatars {
        float: left;
        cursor: pointer;
    }
}

.sh-asset-ls-item {
    @include asset-list-item();
}

$list-gravatar-size: 24px;

.shared-with-list-avatar {
    float: left;
    margin-left: $margin-overlap;
    margin-top: math.div($list-height, 2) - math.div($list-gravatar-size, 2) - $list-padding;

    .avatar {
        @include square(24px);
        min-width: 24px;
        line-height: 24px;
        border: none;
    }
}
