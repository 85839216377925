.file-list-wrap {
    border-bottom: 1px solid $border-color;
}

.task-title-input {
    @include respond_to(phone) {
        flex-basis: 100% !important;
        margin-right: 0 !important;
        margin-bottom: 7px;
    }

    margin-right: 15px;
}

.task-scheduler {
    #select-job-type {
        width: 230px;
        margin: 7px 0;
    }

    .job-setting {
        display: inline-flex;
        align-items: center;
        gap: 15px;
    }
}

.schedule-mode-row {
    display: flex;
    align-items: center;
    margin-bottom: 7px;

    .number-input {
        width: 50px;
        font-size: 13px;
        margin: 0 7px;
        text-align: right;
    }

    .mode-checkbox {
        min-width: 85px;
        flex-shrink: 0;
    }

    .time-select-wrap {
        display: flex;
        align-items: center;
        position: relative;
    }
}

.weekday-checkboxes {
    display: flex;
    flex-wrap: wrap;

    #every {
        @include respond_to(phone) {
            width: 50px;
            margin-right: 0;
        }

        display: inline-block;
        font-weight: 500;
        font-size: 11px;
        margin-right: 8px;
    }

    .weekdays-row {
        display: flex;
        align-items: center;
        flex-shrink: 0;

        .vw-checkbox-small {
            width: 50px;
            height: 20px;
        }
    }
}
