@use 'sass:math';

/* List view variables */
/*
   All the values are being calculated. If you want to change height and thumbnail size,
   you have to update only the list-height and list-thumb-size parameters.
   Always use values that are divisible by 2 and make sure the height is more than the thumb size.
   The same applies to the thumb folders variables below.
*/
$list-height: 50px;
$list-thumb-size: 24px;
$list-padding: math.div($list-height, 2) - math.div(42px, 2);
$list-line-height: 42px;  /* The height of a list asset item minus double the padding */
$list-image-padding: 60px; /* $list-thumb-size + 2 * $list-padding;  Thumbs are absolutely positioned, so we add padding to fit them */

/* The columns bar */
.col-bar {
    padding-right: 20px; /* Because of the always showing scrollbar */
    line-height: 38px;
    height: 38px;

    .attr:hover {
        cursor: pointer;
    }

    .icon-sort-down-16,
    .icon-sort-up-16 {
        font-size: 16px;
    }
}

/* Should be active only in list view. Common for list files and columns bar */
.list-item {
    border-bottom: 1px solid $border-color;
}

.list-selected-light {
    box-shadow: 0 -1px 0 $vw-annual-color;
    border-bottom: 1px solid $vw-annual-color !important;

    .lds--grey {
        border-color: $vw-annual-color !important;
        border-top-color: transparent !important;
    }
}

/* List View */
@mixin asset-list-item() {
    @include make-row();
    @include shared-badge(115%, 116%);
    @include permission-badge(148%, 28%);
    @include asset-list-item-thumb();

    position: relative;
    height: $list-height;
    padding: $list-padding 0;

    .file-thumbs-loader {
        max-width: $list-padding;
        position: relative;
    }

    /* List for sm+ screens */
    .attr {
        line-height: $list-line-height;
    }

    .name {
        padding-left: $list-image-padding;

        .extra-row {
            position: absolute;
            bottom: 0;
            height: 15px;
            line-height: 1;
            color: $text-secondary-color;
            font-size: 11px;

            display: flex;
            align-items: center;
        }

        .extra-row .icon {
            font-size: 16px;
        }
    }

    /* To make only the link clickable and not the whole row */
    .name-wrapper {
        float: left;
    }

    .flags {
        display: flex;
        align-items: center;
        height: $list-line-height;

        .flag {
            font-size: 16px;
            margin: 0 4px;
        }
    }
}

@mixin list-name-path-attr() {
    .name-text {
        @include make-xs-column(12);
        padding: 0;
        margin-top: 4px;

        .icon {
            font-size: 16px;
        }

        a,
        span {
            font-size: 15px;
        }
    }

    .path {
        @include make-xs-column(12);
        padding: 0;
        margin-top: -3px;

        color: $text-secondary-color;
        font-size: 11px;
    }
}

.list-drop-hover {
    &::after {
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: inline !important;
        position: absolute;
        border-top: solid 2px $vw-annual-color;
        border-bottom: solid 2px $vw-annual-color;
        background: rgba($vw-annual-color, 0.3);
    }
}
