/*
    STYLES FOR THE RELATED FILES COMPONENT IN THE FILEVIEW
*/
.fv-related-files {
    @include respond-to(phone) {
        width: 100%;
    }

    @include respond-to(tablet) {
        width: 500px;
    }

    width: 600px;
    color: $vw-grey;
    align-self: center;
}

.fv-generated-files-msg {
    font-weight: 700;
    color: $vw-grey;
}

.vwx-download {
    margin-left: 15px;
}

// List of generated files
.fv-generated-files {
    color: $vw-annual-color;
    padding-right: 20px;

    .fv-default-icon {
        color: $vw-annual-color;
    }

    .svg-file-icon.fv-default-icon svg path:not([fill='none']) {
        fill: $vw-annual-color;
    }
}

.fv-list-item {
    height: 50px;
    display: flex;
    align-items: center;
    text-align: initial;

    .item-name {
        @include text-collapse();
        flex: 1;
        margin-left: 20px;
    }
}

.fv-thumb {
    @include square(24px);
    position: relative;

    .fv-thumb-image {
        @include square(24px);
        position: absolute;
        top: 0;
        right: 0;
    }

    .fv-default-icon {
        font-size: var(--vcs-icon-size-md);
    }
}

// List of types of files that were not generated but can be
.fv-not-generated-files {
    padding-right: 20px;
    color: $vw-grey;

    .fv-default-icon {
        color: $vw-grey;
    }
}
