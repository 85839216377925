#dialog-open-session {
    .di-list-item {
        transition: background 0.4s ease;
        overflow: hidden;

        &:hover {
            transition: background 0.25s ease;
            background: rgba($vw-annual-color, 0.05);
            cursor: pointer;
        }
    }

    .icon-session {
        position: absolute;
        top: 4px;
        right: -7px;
        font-size: var(--vcs-icon-size-md);
    }
}
