/* Links creation dialog. Compose mail styling is below. */

.shareable-items {
    display: flex;
    flex-direction: column;
}

.shareable-item {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $border-color;
    flex-shrink: 0;

    padding: 10px;

    .expire-section {
        margin-left: 50px;
    }
}

.link-row {
    display: flex;
    align-items: center;

    .file-name-row {
        @include primary-secondary-canvas(14px, 18px, 11px, 14px);
        flex: 1;

        display: flex;
        flex-direction: column;

        margin: 0 18px;
    }

    .icons {
        display: flex;
        align-items: center;
        flex: none;
    }

    .icon {
        margin: 0 12px;
        flex: none;
        font-size: 16px;
        vertical-align: middle;
        cursor: pointer;
    }

    .icon.create-link-btn {
        font-size: var(--vcs-icon-size-md);
    }

    .tool-active {
        color: $text-primary-color;

        &::after {
            top: -7px;
            left: -7px;
            height: 36px;
            width: 36px;
            background-color: $border-color;
        }
    }
}

.vr-wrap,
.qr-wrap {
    width: 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .svg-wrapper {
        flex: 1;
        width: 100%;
    
        img {
            width: 100%;
        }
    }
}

.vr-wrap {
    padding: 10px;
    width: 200px;

    .expires {
        font-size: 12px;
        line-height: 14px;
        color: var(--vcs-color--red);
    }
}

/* Compose mail dialog */

#dialog-compose-mail {
    .di-input[contenteditable=true] {
        overflow: auto;
    }
}

.links-list {
    padding-left: 10px;
    list-style-type: disc;
    list-style-position: inside;

    .mail-file-link {
        @include text-collapse();

        a {
            display: inline-block;
            pointer-events: none;
        }
    }
}
