.thumb-sorter {
    position: absolute;
    top: 3px;
    right: 5px;
    z-index: index($main-z, 'thumb-sorter');

    .vw-select {
        background-color: white;
        border: 1px solid $border-color;
    }
}

.thumb-sort-executor {
    height: 34px;
    background-color: white;
    border: 1px solid $border-color;
    float: right;
    padding: 0 5px;
    margin: 0 5px;

    display: flex;
    align-items: center;

    span {
        margin-top: 5px;
    }
}
