.ping-animator {
    width: 22px;
    height: 22px;

    position: absolute;
    top: 5px;
    left: 5px;

    background-color: $vw-annual-color;
    border-radius: 100%;

    transition: all 0.6s ease-out;
    opacity: 0;
}

@keyframes opacity-fade-50 {
    0% {opacity: 0;}
    50% {opacity: 0.6;}
    100% {opacity: 0;}
}

@keyframes opacity-fade-75 {
    0% {opacity: 1;}
    75% {opacity: 1;}
    100% {opacity: 0;}
}

@keyframes collapse-to-target {
    0% {
        opacity: 1;
        transform: scale(1);
    }
  
    75% {
        opacity: 0.75;
    }
  
    100% {
        opacity: 0;
        transform: scale(0.1);
    }
}

.ping-animator.active {
    width: 72px;
    height: 72px;

    top: -20px;
    left: -20px;

    animation-delay: 1.5s;
    animation: opacity-fade-50 1.5s;
    opacity: 0;
}
