.same-mails {
    padding: 0 $di-padding-inner;
    margin: 30px 0;
}

/* The section that appears when you have */
.same-mail {
    @include respond_to(phone) {
        flex-direction: column;
        align-items: flex-start;
    }

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;

    .email {
        font-size: 12px;
    }

    select {
        @include vw-dropdown-std(40%, 34px);
        @include respond_to(phone) {
            margin-top: 10px;
            width: 100%;
        }
    }
}

.currently-shared-with {
    @include respond_to(phone) {
        flex: 1;
    }

    padding: 0 $di-padding-inner;
    margin-top: 20px;
    height: 210px; /* fixed length, including shared-with profiles height, the label and the margins */

    .shared-with-profiles {
        @include respond_to(phone) {
            height: 100%;
        }

        height: 180px;
        list-style-type: none;
        overflow: auto;
        margin-top: 10px;
    }
}

.shared-with-profiles-wrapper {
    height: 100%;
}

$sh-with-profile-height: 52px;
$sh-with-profile-height-expanded: 76px;

@keyframes showin {
    from {
        height: 0;
        line-height: 0;
        opacity: 0;
    }

    to {
        height: 20px;
        line-height: 20px;
        opacity: 1;
    }
}

.shared-with-profile {
    height: $sh-with-profile-height;
    padding: 0 $di-padding-inner 0 15px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    transition: height 0.3s ease-in;

    .avatar-info {
        flex: 1;
        overflow: hidden;
        margin-right: 7px;
    }

    .permission-dropdown {
        @include vw-dropdown-std(35%, 34px);
        flex-shrink: none;
    }

    .icon-right-arrow-16 {
        left: 0;
        position: absolute;
        cursor: pointer;
    }

    .icon-status-warning-16 {
        color: $vw-annual-color-orange;
    }

    .profile-detail {
        padding-right: 2px;
        display: flex;

        div:first-child {
            flex: 1;
        }

        div {
            height: inherit;
            line-height: inherit;
            flex: 2;
        }

        .pending {
            top: 35px;
            position: absolute;
            font-size: 11px;
            color: $vw-annual-color-orange;
        }
    }

    .pd-animated {
        animation: showin 0.3s both;
        animation-delay: 50ms;
    }
}

.shared-with-profile.expanded {
    height: $sh-with-profile-height-expanded;

    .icon-right-arrow-16 {
        transform: rotate(90deg);
    }
}

@keyframes loading {
    from {
        left: -200px;
        width: 30%;
    }
    50% {width: 30%;}
    70% {width: 70%;}
    80% { left: 50%;}
    95% {left: 120%;}
    to {left: 100%;}
}

#dialog-share {
    /* Temporary fix for the sharing permissions dropdown*/
    min-height: 350px;

    .di-msg-error {
        bottom: -40px;
    }
}

.share-error-item {
    display: flex;
    border-bottom: 1px solid $border-color;
    margin-bottom: 10px;

    .di-thumb {
        flex-shrink: 0;
    }

    .share-error-info {
        @include title-descr-canvas--small;
        
        flex: 1;
        margin-left: 15px;
        padding-bottom: 10px;

        .descr {
            margin-top: 10px;
            color: $vw-red !important;
        }
    }
}
