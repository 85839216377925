/* COMMENTS COMPONENT IN FILE VIEW */

$background-color: rgba($black, 0.04);
$comments-component-width: 320px;

.comments-section {
    @include respond-to(phone) {
        top: $toolbar-height-xs;
    }
    @include panel-animation($comments-component-width, right);
    @include panel-shadow--left;

    top: $toolbar-height;
    bottom: 0;
    // need to override panel-animation's fixed position
    // because it's breaking comments in public links
    position: absolute;

    overflow: hidden;
    z-index: index($fileview-z, 'panel');
    
    display: flex;
    flex-direction: column;
    background-color: var(--bg-color);

    .tool-active::after {
        @include respond-to(phone) {
            top: 13px;
            left: 10px;
            height: 28px;
            width: 28px;
        }
        top: 16px;
        left: 13px;
        height: 32px;
        width: 32px;
        position: absolute;
        content: '';
        border-radius: 50%;
        z-index: -1;
    }
}

.comments-header {
    @media (max-width: $xs-max-width) {
        height: $toolbar-height-xs;
    }

    position: relative;
    height: $toolbar-height;
    padding: 0 11px;
    color: $vw-grey;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
        font-size: 15px;
        text-transform: uppercase;
        font-weight: bold;
    }

    .comments-tools {
        display: flex;
        align-items: center;

        .tool {
            font-size: 16px !important;
        }

        .tool::after {
            top: 19px;
            left: 6px;
            height: 24px;
            width: 24px;
        }
    }
}

.comment-field {
    position: relative;

    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.comment-one-liner {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex: 1 0 auto;
    padding: 8px 0;

    &:hover {
        background-color: var(--bg-color--dark);
    }
}

.comment-column {
    min-width: 0;
    padding-left: 5px;


    display: flex;
    flex-direction: column;
    flex: 1;
}

.comment-simple {
    display: flex;
    flex: 1;
    flex-direction: column;

    .content {
        min-width: 0;
        flex: 1;
    }
}

.posted-info {
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;
}

.pub-date {
    padding-left: 3px;
    font-size: 10px;
}

.comment-field-wrapper {
    display: flex;
    position: relative;
    flex-direction: column;
}

.comment-actions {
    height: 32px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: end;
}

.comment-action-btn {
    margin: 0 3px;
}

.comment-wrapper {
    padding: 8px;
    user-select: text;
}

.comment-resolved {
    background-color: rgba(var(--vcs-color--primary-rgb), 0.1);
}

.author-actions {
    display: flex;
    margin: 5px auto;
    gap: 5px;
}

.more-replies {
    margin-left: 20px;
    cursor: pointer;
}


.not-authenticated {
    padding: 5% 0;
    margin: 0 auto;
    align-items: flex-end;
    justify-content: center;

    .icon {
        font-size: 64px;
    };
}


.does-not-exist {
    padding: 5% 0;
    margin: 0 5px;
    align-items: flex-end;
    justify-content: center;

    .icon {
        font-size: 64px;
    };
}
