$asset-library-width: 350px;

.file-library {
    display: flex;
    flex-direction: column;
    background-color: white;

    .items-container {
        flex: 1;
        overflow: auto;
    }
}

.library-ls-item {
    border-bottom: 1px solid $border-color;

    .name {
        @include make-xs-column(12);
    }
}

.library-asset-ls-item {
    @include asset-list-item();
}

.file-library--panel {
    @include respond-to(phone) {
        top: $navbar-height-xs + $toolbar-height-xs;
    }
    @include panel-animation($asset-library-width, right);
    @include panel-shadow--left;
    top: $navbar-height + $toolbar-height;
    bottom: 0;

    .icon16 {
        margin-right: 5px;
    }
}

#dialog--file-library {
    .file-library {
        flex: 1;
        overflow-y: auto;
    }
}
