.upload-overlay {
    @include respond-to(large_screen) {
        left: $sidebar-width;
    }

    visibility: hidden;

    position: fixed;
    left: 0;
    right: 0;
    height: 100%;

    margin-top: -1px;

    background-color: rgba($vw-annual-color, 0);
    border: solid 0 transparent;
    opacity: 0;

    z-index: index($main-z, 'upload-overlay');
    transition: all 0.3s linear;
    pointer-events: none;

    .icon {
        margin: 5px;
    }
}

.upload-overlay-visible {
    visibility: visible;

    background-color: rgba($vw-annual-color, 0.3);
    border: solid 3px $vw-annual-color;
    opacity: 1;
}

.upload-notification {
    @include respond-to(phone) {
        width: 300px;
    }

    visibility: hidden;

    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    margin: 0 auto;

    display: flex;

    min-height: 55px;
    width: 350px;

    background-color: $vw-annual-color;
    border-radius: 5px;
    color: $white;
    text-align: center;
    opacity: 0;

    transition: all 0.3s linear;

    .icon-upload {
        font-size: var(--vcs-icon-size-md);
    }

    .upload-folder {
        @include text-collapse();
        width: 300px;
        font-weight: bold;
        overflow: hidden;
    }

    .upload-message {
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 14px;
    }
}

.upload-notification-visible {
    visibility: visible;

    opacity: 1;
    top: 10px;
}
