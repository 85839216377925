.shwm-ls-item {
    border-bottom: 1px solid $border-color;

    .name {
        @include respond_to(phone) {
            @include make-xs-column(8);
            @include list-name-path-attr();
        }

        @include make-sm-column(6);
        @include make-lg-column(7);
    }

    .sh-date {
        /* stylelint-disable */
        @extend .hidden-xs;
        /* stylelint-enable */
        @include make-xs-column(2);
    }

    .sh-by {
        @include make-xs-column(2);
        @include make-sm-column(3);
        @include make-lg-column(2);
    }

    .flags {
        @include make-xs-column(2);
        @include make-sm-column(1);
        @include make-lg-column(1);

        .flag {
            position: unset !important;
        }
    }
}

.shwm-asset-ls-item {
    @include asset-list-item();
}
