.slide-ls-item {
    border-bottom: 1px solid $border-color;

    .name {
        @include make-xs-column(9);
        @include make-sm-column(5);
        @include make-lg-column(6);
    }

    .last-mod {
        /* stylelint-disable */
        @extend .hidden-xs;
        /* stylelint-enable */
        @include make-xs-column(2);
        @include make-sm-column(3);
        @include make-lg-column(2);
    }

    .type {
        /* stylelint-disable */
        @extend .hidden-xs;
        /* stylelint-enable */
        @include make-xs-column(2);
    }

    .order {
        @include make-xs-column(3);
        @include make-sm-column(2);
    }
}

.slide-asset-ls-item {
    @include asset-list-item();

    .board-layout {
        height: 100%;
        font-size: 1px !important;

        h1 { font-size: 3px !important; }
        h2 { font-size: 2px !important; }

        h3,
        p,
        ul,
        li {
            font-size: 1px !important;
        }
    }
}

.asset {
    .ib-slide-empty {
        font-size: 7px;
    }
}
