@use 'sass:math';

$icons-font-size: 24px;

.toolbar {
    @media (max-width: $xs-max-width) {
        height: $toolbar-height-xs;
    }
    height: $toolbar-height;
    padding: 0;
    background-color: var(--white);
    border-bottom: 1px solid $border-color;
    z-index: index($main-z, 'toolbar');
}

.tb--flex {
    display: flex;
}

.tb__breadcrumbs-and-tools {
    flex: 1;
    display: flex;
    align-items: center;
    min-width: 0;
}

.tb__global-tools {
    flex-shrink: 0;
}

.tb__ctx-tools {
    flex: 1;
    display: flex;
    justify-content: space-between;
    flex-flow: row-reverse;
}

.tb__breadcrumbs {
    flex: 1;
    min-width: 0;
}

.tb__ctx-tools__actions {
    display: flex;

    .dropdown-item {
        @include ctx-menu-item();
    }
}

.tb__ctx-tools__actions--right {
    flex-flow: row-reverse;

    .dropdown-menu {
        right: 0;
        left: auto;
    }
}

.tool {
    @media (max-width: $xs-max-width) {
        line-height: $toolbar-height-xs !important;
        padding: 0 5px;
    }
    @include link-no-decoration($text-primary-color);
    @include vw-hover-animation($text-primary-color);

    line-height: $toolbar-height - 1 !important;
    padding: 0 10px;
    font-size: $icons-font-size;
    float: left;

    position: relative;

    .ping-animator {
        top: 20px;
        left: 15px;
    }

    .ping-animator.active {
        top: -5px;
        left: -10px;
    }
}

.tool-active {
    color: $white;
    position: relative;
    z-index: 0;

    &:hover,
    &:visited,
    &:active,
    &:focus {
        color: $white;
    }

    &::after {
        @media (max-width: $xs-max-width) {
            height: 38px;
            width: 38px;
        }

        top: 11px;
        left: 2px;
        height: 40px;
        width: 40px;
        position: absolute;
        content: '';
        background-color: $vw-annual-color;
        border-radius: 50%;
        z-index: -1;
    }

}

.gl-tools {
    display: flex;
    float: right;
}

.tb__selection-counter {
    float: right;
}

.context-tools {
    @include respond_to(large_screen) {
        float: right;
    }

    float: left;
}

.context-tools.mobile {
    @include respond_to(large_screen) {
        float: left;
    }
}

.context-tools {
    .dropdown {
        float: left;
    }

    .dropdown-menu {
        @include respond_to(large_screen) {
            right: 0;
            left: auto;
        }
        padding: 8px 0;
    }

    .dropdown-item {
        @include ctx-menu-item();
    }
}

.tool-separator {
    @include respond-to(phone) {
        margin-top: math.div($toolbar-height-xs, 2) - math.div($icons-font-size, 2);
    }
    float: right;
    height: $icons-font-size;
    padding-right: 10px;
    margin-top: math.div($toolbar-height, 2) - math.div($icons-font-size, 2);
    background-color: white;
    border-left: 1px solid lightgrey;
    z-index: 0;
}

.toolbar-prim-text {
    @include respond_to(phone) {
        line-height: $toolbar-height-xs;
    }

    @include text-collapse();
    line-height: $toolbar-height;
    margin-left: 15px;
    padding-right: 15px;
    font-size: 16px;
    font-weight: bold;
    color: $vw-grey;
}

.toolbar-secondary-text {
    @include respond_to(phone) {
        line-height: $toolbar-height-xs;
    }

    line-height: $toolbar-height;
    font-size: 16px;
    color: $vw-grey;
}

.toolbar-duo-text {
    @include primary-secondary-canvas(16px, 16px, 12px, 14px);
    @include respond_to(large_screen) {
        margin-left: 15px;
        margin-top: math.div($toolbar-height, 2) - 8px;
    }

    max-width: 80%;
    margin-top: 10px;
    margin-left: 0;

    .prim {
        color: $vw-grey;
        font-weight: bold;
    }
}

.toolbar-text-detailed {
    @include respond_to(phone) {
        height: $toolbar-height-xs;
    }

    height: $toolbar-height;
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .detailed-prim {
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
        color: $text-primary-color;
    }

    .detailed-secondary {
        font-size: 14px;
        color: $vw-grey;
    }
}
