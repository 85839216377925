@import 'notification';

$notifications-component-width: 400px;

#notifications-root {
    position: relative;
    cursor: pointer;

    > .icon {
        position: relative;
    }

    .icon::after {
        height: 40px;
        width: 40px;
        left: -4px;
        top: -3px;
        position: absolute;
        background-color: $vw-annual-color;
        opacity: 0;
        border-radius: 50%;
        content: '';
        z-index: -1;
        transition: opacity 0.25s ease;
    }

    .icon-bell-noborder-32.open::after {
        opacity: 1;
    }
}

.unseen-badge {
    width: auto;
    min-width: 20px;
    height: 20px;
    margin-left: 25px;
    margin-top: -12px;
    position: absolute;
    font-size: 12px;
    line-height: 20px;
    font-weight: bold;
    border-radius: 50%;
    text-align: center;
    color: white;
    background-color: $vw-annual-color;
    box-shadow: -0.707px 0.707px 2px 0 rgba(0, 0, 0, 0.72);
    font-family: 'Roboto', 'Noto Sans', sans-serif;

    z-index: 1;
}

.notifications-panel {
    @include respond-to(phone) {
        width: 100%;
        max-width: 100%;
    }
    @include panel-animation($notifications-component-width, right);
    @include panel-shadow--left;

    top: 0;
    padding: 0;

    height: 100%;
    position: absolute;
    background-color: $white;

    display: flex;
    flex-direction: column;

    border: 0;
    border-radius: 0;

    z-index: 5;
}

#notifications-list {
    max-width: inherit;

    flex: 1;
    overflow: auto;
}

.notifications-header {
    @media (max-width: $xs-max-width) {
        height: $toolbar-height-xs;
    }

    position: relative;
    height: $toolbar-height;
    padding: 0 11px;
    color: $vw-grey;
    border-bottom: 1px solid $border-color;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
        font-size: 16px;
        text-transform: uppercase;
        font-weight: bold;
    }
}

.section-title {
    padding: 0 15px;

    font-size: 13px;
    font-weight: bold;
    line-height: 43px;
    text-transform: uppercase;
    text-align: center;

    color: $vw-grey;
}

.notifications-end {
    margin: 0;
    padding: 15px;

    width: 100%;

    font-size: 11px;
    font-weight: bold;
    color: $text-secondary-color;

    display: flex;
    justify-content: center;
}

.infinite-scroll-component__outerdiv {
    margin-right: 10px;
}
