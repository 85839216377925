@font-face {
    font-family: 'Glyphicons Halflings';
    src: url('fonts/bootstrap/glyphicons-halflings-regular.eot');
    src: url('fonts/bootstrap/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'), url('fonts/bootstrap/glyphicons-halflings-regular.woff2') format('woff2'), url('fonts/bootstrap/glyphicons-halflings-regular.woff') format('woff'), url('fonts/bootstrap/glyphicons-halflings-regular.ttf') format('truetype'), url('fonts/bootstrap/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg');
}

.glyphicon {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.glyphicon-option-vertical::before {
    content: '\e235';
}

.glyphicon-th-large::before {
    content: '\e010';
}

.glyphicon-th::before {
    content: '\e011';
}

.glyphicon-th-list::before {
    content: '\e012';
}

.glyphicon-envelope::before {
    content: '\2709';
}

.glyphicon-remove::before {
    content: '\e014';
}

.glyphicon-cloud-upload::before {
    content: '\e198';
}

.glyphicon-trash::before {
    content: '\e020';
}
