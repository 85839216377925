.notification-item {
    padding: 16px;
    padding-right: 20px;
    margin-bottom: 10px;

    position: relative;

    font-size: 12px;
    line-height: 18px;

    background-color: $white;
    transition: background-color 0.3s ease;

    display: flex;
    flex-direction: column;

    overflow: hidden;

    .toggle-ntf {
        position: absolute;
        right: 10px;

        transform: rotate(90deg);
        transition: transform 0.4s ease-in-out, opacity 0.3s ease-in-out;

        cursor: pointer;
    }

    &:hover {
        background-color: rgba($black, 0.02);

        .actions {
            opacity: 1;
        }
    }

    .main {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex: 1 0 auto;

        overflow: hidden;
    }

    .content {
        margin-left: 15px;
        margin-right: 3px;

        flex: 1;

        font-size: 13px;
        line-height: 20px;

        display: -webkit-box;  // stylelint-disable-line
        -webkit-box-orient: vertical;  // stylelint-disable-line
        -webkit-line-clamp: 3; // stylelint-disable-line
        white-space: normal;
        overflow-wrap: break-word;
        word-break: break-word;

        transition: height 0.3s cubic-bezier(0, 0, 0.2, 1);

        p {
            margin: 0;
            padding: 0;
        }
    }

    .notification-footer {
        margin-left: 45px;
        margin-right: -10px;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .time {
        font-size: 11px;
    }

    .actions {
        opacity: 0;
        transition: opacity 0.3s ease-in-out;

        display: flex;
        align-items: center;

        cursor: pointer;

        i {
            margin: 0 3px;
            font-size: 16px;
        }
    }

    a,
    a:hover {
        word-break: normal;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.notification-item.notification-type-share .actions {
    opacity: 1;
}

.notification-item.new {
    background-color: rgba($black, 0.05);
}

.notification-item.overflowing {
    cursor: pointer;
}

.notification-type-announce::before {
    width: 3px;
    height: 100%;

    left: 0;
    top: 0;
    position: absolute;

    content: '';
    background-color: $vw-annual-color-orange;

    z-index: 1;
}

.notification-item.expanded {
    .toggle-ntf {
        transform: rotate(-90deg);
    }

    .content {
        -webkit-line-clamp: unset;
        white-space: unset;
    }
}
