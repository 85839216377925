#dialog-vr-code,
#dialog-qr-code {
    .dialog-header {
        border-bottom: 0;
    }

    .content {
        .title {
            margin: 0 15px;
            color: $vw-annual-color;
            font-size: 22px;
            font-weight: bold;
            text-align: center;
        }

        #qr-canvas {
            margin: 15px 0;
            max-height: 244px;
            max-width: 244px;
        }

        .short-code {
            margin: 15px 0;
            font-size: 38px;
            font-weight: bold;
            line-height: 48px;
            font-family: 'Consolas', 'Monaco', 'Courier New';
        }

        .expires {
            font-size: 12px;
            line-height: 14px;
            color: var(--vcs-color--red);
        }

        .info {
            font-size: 12px;
            color: $vw-grey;
            width: 270px;
            margin-bottom: 10px;
            text-align: center;
        }
    }

    .prod-btns {
        display: flex;
        margin-top: 5px;

        a {
            display: block;
            width: 103px;
            margin: 0 2px;
        }
    }
}
