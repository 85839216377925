.task-ls-item {
    border-bottom: 1px solid $border-color;

    .name {
        @include make-xs-column(10);
        @include make-sm-column(4);
        @include make-lg-column(6);
        @include list-name-path-attr();
    }

    .next-run,
    .last-run {
        /* stylelint-disable */
        @extend .hidden-xs;
        /* stylelint-enable */
        @include make-sm-column(3);
        @include make-lg-column(2);
    }

    .status {
        @include make-xs-column(2);

        .onoffswitch {
            margin-top: 8px;
        }
    }
}

.task-asset-ls-item {
    @include asset-list-item();
}
