.option-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.custom-path {
    flex: 1;
    line-height: 26px;
    min-width: 200px;
    overflow: hidden;

    display: flex;

    .custom-path-inner {
        @include text-collapse-reverse();
    }

    .di-note-sm {
        direction: ltr;
        order: 1;
    }

    .flex-buff {
        flex: 1;
    }
}

.save-in-wrap {
    .save-in-inner {
        display: flex;
        flex-direction: column;
        position: relative;

        margin-left: 20px;
        margin-top: 10px;
    }

    .save-in-row {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 5px;

        .first {
            min-width: 90px;
        }

        .second {
            width: 106px;
            height: 26px;
            margin: 0 10px;
        }

        .vw-btn-secondary {
            padding: 3px;
        }

        .third {
            flex: 1;
            min-width: 200px;
        }
    }
}

.external-section {
    margin-top: 20px;
    border-top: 1px solid $border-color;
}
