$breadcrumb-color: $vw-grey;
$breadcrumb-line-height: 36px;

#breadcrumbs-and-tools {
    @include respond-to(large_screen) {
        border-bottom: 1px solid $border-color;
        box-shadow: none;
    }
    flex: none;
    box-shadow: 0 1px 5px #757575;
    z-index: index($main-z, 'toolbar');
}

.breadcrumbs {
    @include make-lg-column(6);
    padding: 0;
}

.breadcrumbs-dropdown {
    @include respond_to(phone) {
        margin-top: 9px;
        padding-right: 5px;
        height: $toolbar-height-xs - 8px;
    }

    font-size: 28px;
    margin-top: 14px;
    padding-left: 7px;
    height: $toolbar-height-xs - 18px;

    &:hover {
        cursor: pointer;
    }
}

.breadcrumbs-inner {
    @include respond_to(phone) {
        height: $toolbar-height-xs;
    }

    height: $toolbar-height;
    display: flex;
    align-items: center;
    margin-left: 10px;

    .active {
        position: relative;
        max-width: none;
        margin-right: 15px;
        flex: 1;

        font-weight: bold;
        outline: none;

        &:hover {
            outline: none;
            cursor: default;
        }
    }

    .active a {
        display: inline-block;
        max-width: 100%;
        position: relative;
        padding-right: 30px;
    
        .icon-close {
            position: absolute;
            right: 0;
            top: 2px;
            pointer-events: all;
        }
    }

    .sep {
        @include respond_to(phone) {
            line-height: $toolbar-height-xs !important;
        }
        flex: 0 0 auto;
        line-height: $toolbar-height;
        font-size: 13px;
        margin: 0 3px;
        color: $breadcrumb-color;
    }
}

.breadcrumb {
    @include text-collapse;
    @include respond_to(phone) {
        outline: 1px solid $border-color;
    }
    max-width: 160px;
    padding: 0 5px;
    font-size: 16px;
    color: $breadcrumb-color;
    flex: 0 0 auto;

    a {
        @include text-collapse;
        @include link-no-decoration($breadcrumb-color);
        display: inline;
        line-height: $breadcrumb-line-height;
    }

    &:hover {
        outline: 1px solid $border-color;
    }
}
