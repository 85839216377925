.trash-ls-item {
    border-bottom: 1px solid $border-color;

    .name {
        @include respond_to(phone) {
            @include make-xs-column(12);
        }
        @include make-sm-column(9);
        @include make-lg-column(10);
        @include list-name-path-attr();
    }

    .last-mod {
        /* stylelint-disable */
        @extend .hidden-xs;
        /* stylelint-enable */
        @include make-sm-column(3);
        @include make-lg-column(2);
    }
}

.trash-asset-ls-item {
    @include asset-list-item();
}


.empty-trash-btn-sm {
    @include button(var(--grey10), var(--white), var(--border-color));

    padding: 4px 6px;
    min-width: 88px;

    font-size: 13px;
    font-weight: bold;

    text-align: center;
    color: var(--grey10);
}

.trash {
    background-color: $page-bg-color;
}

.message-bubble {
    @include respond-to(phone) {
        height: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        button {
            margin-top: 15px;
        }
    }

    width: auto;
    height: 44px;
    margin: 10px;
    padding: 10px;

    background-color: var(--white);
    border: 1px solid var(--border-color);
    border-radius: 10px;

    font-size: 13px;
    font-weight: bold;

    display: flex;
    justify-content: space-between;
    align-items: center;
}
