/* Thumb view global variables */
$slide--thumb-width: 373px;
$file-slide--thumb-height: 340px;
$file-slide--thumb-size: 210px;

.slide--thumbs-wrapper {
    @mixin auto-size {
        padding: 0 5px;
        margin-bottom: 10px;
        user-select: none;
        -webkit-user-select: none; // stylelint-disable-line

        .slide--thumb {
            width: 100%;
            margin-left: 0;
        }

        .file-slide--thumb .presentation-asset-img {
            width: 100%;
            height: auto;
            padding-bottom: 56.25%;
        }

        .name {
            width: 100%;
        }
    }

    @include respond-to(phone) {
        @include auto-size();
        width: 100%;
    }

    @include respond-to(tablet) {
        @include auto-size();
        width: 50%;
    }

    @include respond-to(small_screen) {
        @include auto-size();
        width: 33%;
    }

    @include respond-to(large_screen) {
        @include auto-size();
        width: 25%;
    }

    position: relative;
    float: left;

    .item-selected-light {
        border-bottom: 0 !important;
        border-top: 0 !important;

        .icon-comment-indicator-16 {
            color: $text-secondary-color !important;
        }
    }

    .comments-count {
        display: flex;
        align-items: center;
        transition: color 0.4s ease;

        .icon {
            font-size: 16px;
        }
    }
}



.slide--thumb {
    position: relative;
    width: $slide--thumb-width;
    margin: 0;
    padding: 0;
    float: left;

    font-size: 12px;
    background-color: $file-bg-color;
    box-shadow: $thumb-shadow;

    .name {
        width: 100%;
    }
}

.slide--thumb.item-selected-light {
    @include thumb-selected-shadow(2px, 0);
}

/* Thumbs File View */
.file-slide--thumb {
    .presentation-asset-img {
        @include fit-background--cover;
        height: $file-slide--thumb-size + 1px;
        width: $slide--thumb-width;
        position: relative;
        display: flex;
    }

    .presentation-layout-slide--thumb {
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        display: flex;

        // This prevents drag/drop bug if you click and drag
        // and image that is part of the minified preview
        // that is being displayed as a thumbnail of a slide
        pointer-events: none;
    }

    .thumb-default-icon {
        font-size: var(--vcs-icon-size-md);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(2);
        zoom: 0.5;
        /* stylelint-disable */
        -ms-zoom: 0.5;
        -moz-transform: translate(-50%, -50%) scale(1);
        /* stylelint-enable */
    }

    .layout-tell {
        height: 100%;
    }

    .name {
        max-width: 100%;
    }
}

.presentation__thumb_info {
    padding: 8px;
    display: flex;
    align-items: center;

    .info {
        @include primary-secondary-canvas(14px, 16px, 12px, 14px);
        flex: 1;
        padding-right: 8px;
        overflow: hidden;
    }

    .flag {
        font-size: 16px;
        margin-left: 8px;
    }
}

.slide--thumb-info {
    height: 56px;
    color: $text-primary-color;
    display: flex;
    flex-direction: column;

    padding: 0 7px;

    .text-prim {
        font-size: 14px;
        color: $text-primary-color;
        line-height: 32px;
        display: inline-flex;
        align-items: center;
    }

    .text-secondary {
        font-size: 12px;
        color: $text-secondary-color;
        text-transform: none;

        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .name {
        @include text-collapse();
        padding: 0 7px;
        flex: 1;
    }

    .icon {
        flex-shrink: 0;
        font-size: var(--vcs-icon-size-md);
    }
}

/* Presentation landing page */
.land-slide--thumb {
    color: #79767b;

    &:hover {
        background-color: $white !important;
    }

    .file-slide--thumb {
        cursor: pointer;
    }

    .name {
        padding: 0;
    }

    .text-prim {
        max-height: 40px;
        padding: 10px 0;
        display: flex;
    }
}
