#dialog-select-file,
#dialog-choose-mount-location {
    height: 500px;
}

browse-dialog-contents {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .file-navigator {
        position: relative;
        display: flex;
        align-items: center;
        flex-shrink: 0;
        max-width: 100%;
        height: $toolbar-height;
        border-bottom: 1px solid $border-color;

        breadcrumbs {
            flex: 1;
            min-width: 1px;
        }
    }

    .dropdown-toggle-storage {
        margin-left: 17px;
        color: $vw-grey;
        font-size: var(--vcs-icon-size-md);
        display: flex;
        align-items: center;

        .icon-svg {
            height: var(--vcs-icon-size-md);
            width: var(--vcs-icon-size-md);
            display: inline-block;
        }
    }

    .provider-item {
        @include vw-hover-animation($vw-grey);
        padding: 5px 10px;
        display: block;
        font-weight: 500;
        cursor: pointer;

        .icon {
            margin: 0 4px;
            color: $vw-grey;
            font-size: var(--vcs-icon-size-md);
            vertical-align: middle;
        }
        
        .icon-svg {
            height: var(--vcs-icon-size-md);
            width: var(--vcs-icon-size-md);
            padding: 0;
            margin: 0 4px;
            display: inline-block;
        }
    }

    .items {
        flex: 1;
        width: 100%;
        padding-top: 1px;
        overflow-y: auto;
        user-select: none;
    }

    .di-list-item {
        cursor: pointer;
    }

    .message-box {
        padding: 7% 0;
    }
}
