$background-color: #404040;

video {
    max-height: 100%;
    max-width: 100%;
    margin: 0 auto;
}

video::-webkit-media-controls-panel-container { // stylelint-disable-line
    display: none !important;
}

.full-screen video {
    height: 100%;
    width: 100%;
    margin: auto;
}
