.asset {
    transition: background 0.4s ease;

    &:hover {
        transition: background 0.25s ease;
        background: rgba($vw-annual-color, 0.05);
    }

    .asset-ls-loader {
        position: absolute;
        bottom: -1px;        
    }
}

/* not underlining the dots */
.name-wrapper {
    display: flex;
    max-width: 100%;

    .base-name {
        @include text-collapse();
        position: relative;
    }

    .extension {
        flex: 1 1 auto;
    }
}

/* Common for all the attributes - name, size, etc. */
.attr {
    @include text-collapse();
    font-size: 12px;
    color: $text-primary-color;
}

.attr-primary {
    font-size: 15px;
    color: $text-primary-color;

    a {
        color: $text-primary-color;
        font-size: 15px;

        &:hover {
            color: $vw-annual-color !important;
        }
    }
}

.storage-icon {
    display: inline-block;
    margin-right: 2px;
    margin-top: -2px;

    color: $text-secondary-color;
    font-size: 16px;
    vertical-align: middle;
}

.duo-attr {
    @include text-collapse();

    .name-invalid {
        color: $text-primary-color;
        font-size: 16px;
        vertical-align: middle;
    }
}

.icon-folder {
    color: $vw-grey;
}

.asset-img-default {
    color: $text-primary-color;
}

.svg-file-icon {
    svg {
        width: var(--vcs-icon-size-md);
        height: var(--vcs-icon-size-md);
        transform: rotate(360deg);
        
        path:not([fill='none']) {
            fill: $text-primary-color;
        }
    }
}

.svg-file-icon.folder {
    svg path:not([fill='none']) {
        fill: $vw-grey;
    }
}

.asset .flag {
    float: left;
    color: $vw-grey;

    &:hover {
        cursor: pointer;
        color: $vw-annual-color;
    }
}

.shareable-link-flag {
    display: flex;
    align-items: center;
    
    .link-visits-count {
        font-size: 10px;
        line-height: normal;
        margin-right: 5px;
    }

    .link-visits-count.lc-hidden {
        opacity: 0;
    }
}

.name-invalid {
    font-size: 16px;
    vertical-align: middle;
    
    display: inline-block;
    margin-top: -4px;
    margin-right: 3px;
}

.items-container {
    width: 100%;
    padding-top: 1px;
}

list-columns-bar {
    background-color: var(--white);
}
